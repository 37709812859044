import * as THREE from 'three'
import React, { Suspense, forwardRef, useLayoutEffect, useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { OrbitControls, Environment, useGLTF, Float, PivotControls, QuadraticBezierLine, Backdrop, ContactShadows, Sky, Cloud, Stars, Center } from '@react-three/drei'
import D3Text from './component/D3Text'

function Nebula(props) {

  const model = useGLTF('./models/optimized-planet.glb')

  let mixer
  if (model.animations.length) {
      mixer = new THREE.AnimationMixer(model.scene);
      model.animations.forEach(clip => {
          const action = mixer.clipAction(clip)
          action.play();
      });
  }

  useFrame((state, delta) => {
     mixer?.update(delta*0.5)
     model.scene.traverse((object) => {
      if (object instanceof THREE.Mesh) { //check callback is a mesh
          object.castShadow = true;
          object.receiveShadow = true;
          object.material.envMapIntensity = 10
      }
  });
 
})

  return (
    <primitive object={model.scene} {...props} />
  )
  
}

// Auto-generated by: https://github.com/pmndrs/gltfjsx
const Spaceman = forwardRef(({ children, ...props }, ref) => {
  const { nodes, materials } = useGLTF('/models/Astronaut-transformed.glb')
  useLayoutEffect(() => {
    Object.values(materials).forEach((material) => {
      material.roughness = 0
    })
  }, [])
  return (
    <mesh
      castShadow
      receiveShadow
      ref={ref}
      {...props}
      geometry={nodes.Astronaut_mesh.geometry}
      material={materials.Astronaut_mat}
      material-envMapIntensity={0}
      dispose={null}>
      {children}
    </mesh>
  )
})

// One-click copy/paste from the poimandres market: https://market.pmnd.rs/model/low-poly-spaceship
const Ship = forwardRef((props, ref) => {
  const { nodes, materials } = useGLTF('/models/Spaceship.gltf')
  useLayoutEffect(() => {
    Object.values(materials).forEach((material) => {
      material.roughness = 0
    })
  }, [])
  return (
    <group ref={ref} {...props} dispose={null}>
      <mesh castShadow receiveShadow geometry={nodes.Cube005.geometry} material={materials.Mat0} />
      <mesh castShadow receiveShadow geometry={nodes.Cube005_1.geometry} material={materials.Mat1} material-color="black" />
      <mesh castShadow receiveShadow geometry={nodes.Cube005_2.geometry} material={materials.Mat2} material-envMapIntensity={0.2} material-color="black" />
      <mesh castShadow receiveShadow geometry={nodes.Cube005_3.geometry} material={materials.Window_Frame} />
      <mesh castShadow receiveShadow geometry={nodes.Cube005_4.geometry} material={materials.Mat4} />
      <mesh castShadow receiveShadow geometry={nodes.Cube005_6.geometry} material={materials.Window} />
    </group>
  )
})

function Cable({ start, end, v1 = new THREE.Vector3(), v2 = new THREE.Vector3() }) {
  const ref = useRef()
  useFrame(() => ref.current.setPoints(start.current.getWorldPosition(v1), end.current.getWorldPosition(v2)), [])
  return <QuadraticBezierLine ref={ref} lineWidth={3} color="#ff2060" />
}

export default function App() {
  const spaceman = useRef()
  const ship = useRef()
  return (
    <Canvas shadows camera={{ position: [0, 1.5, 7], far: 1000000 }}>
      <ambientLight intensity={0.2} />
      <directionalLight position={[-10, 0, -5]} intensity={1} color="red" />
      <directionalLight position={[-1, -2, -5]} intensity={0.2} color="#0c8cbf" />
      <spotLight position={[5, 0, 5]} intensity={2.5} penumbra={1} angle={0.35} castShadow color="#0c8cbf" />

      <Float scale={0.75} position={[0, 0.65, 3]} rotation={[0, 0.6, 0]}>
        <Ship ref={ship} />
      </Float>

      <Float position={[1, 1.1, 3]} rotation={[0, 0, 0]} rotationIntensity={4} floatIntensity={6} speed={1.5}>
        <Spaceman scale={0.2}>
          <object3D position={[-0.6, 2, 0]} ref={spaceman} />
        </Spaceman>
      </Float>
      <Cable start={ship} end={spaceman} />
      
      
      {
        //<Environment path="/hdri/" files="potsdamer_platz_1k.hdr" />
      }
      <D3Text />

      <ambientLight intensity={1} />
      <pointLight intensity={2} position={[0, 0, -1000]} />
      <hemisphereLight intensity={0.5} rotation={[0, Math.PI * -0.35, 0]}/>  
      
      <Suspense fallback={null}>
        <Cloud position={[-4, -2, -25]} speed={0.2} opacity={1} color={"#fff"}/>
        <Cloud position={[4, 2, -15]} speed={0.2} opacity={0.5}  color={"#fff"}/>
        <Cloud position={[-4, 2, -10]} speed={0.2} opacity={0.5}  color={"#fff"}/>
        <Cloud position={[4, -2, -5]} speed={0.2} opacity={0.5}  color={"#fff"}/>
        <Cloud position={[4, 2, 0]} speed={0.2} opacity={0.75}  color={"#fff"}/>
      </Suspense>
      <Sky azimuth={0.1} turbidity={10} rayleigh={0.5} inclination={0.6} distance={1000} color={"#000"} />
      <Stars radius={100} depth={50} count={5000} factor={4} saturation={0} fade speed={1} />
  
  
    <Nebula position={[5, 2, -10]} scale={0.015}/>
  
    
  
      

      <OrbitControls makeDefault enablePan/>

    </Canvas>
  )
}
