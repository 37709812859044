import { extend } from "@react-three/fiber"
import { FontLoader } from "three/examples/jsm/loaders/FontLoader"
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import helvetiker from "./ti_regular.json"

const D3Text = () => {
  extend({ TextGeometry })

  const helvetikerRegular = new FontLoader().parse(helvetiker)
  console.log('helvetiker regular ->', helvetikerRegular)

  const textOptions = {
    font: helvetikerRegular,
    size: .4,
    height: .2
  }
  
  return(
    <mesh position={[-1, 1.5, 5]} 
        
      scale={[0.75, 0.75, 0.75]}
      color="white" // default
      anchorX="center" // default
      anchorY="middle" // default
      rotation={[0, 0, 0]}>
      <textGeometry attach='geometry' args={["In development", textOptions]}/>
      <meshLambertMaterial attach='material' color={'gold'}/>
    </mesh>
  )

}

export default D3Text